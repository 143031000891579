import React, { useEffect } from 'react'
import InputTextGroup from '../../components/forms/InputTextGroup'
import Button from '../../components/common/Button'
import ReactSelectCustom from '../../components/forms/ReactSelectCustom'
import InputTextArea from '../../components/forms/InputTextArea'
import InputRadioGroup from '../../components/forms/InputRadioGroup'
import { useFieldArray, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  jobsValidation,
  realEstateValidation,
  validateCreatePost,
  vehicleValidation
} from './createPost.validations'
import Spinner from '../../components/common/Spinner'
import {
  currencies,
  noPriceTitle,
  countryData
} from '../../Utilities/constants'
import { useNavigate } from 'react-router-dom'
import VehiclePostFormComponent from './components/VehiclePostFormComponent'
import JobPostFormComponent from './components/JobPostFormComponent'
import RealEstateFormComponent from './components/RealEstateFormComponent'
import CustomSelectWithGroups from '../../components/forms/CustomSelectWithGroups'
import CustomSelect from '../../components/forms/CustomSelect'
import InputFileGroup from '../../components/forms/InputFileGroup'
import FileUploader from '../../components/common/FileUploader'
import { useStoreNewPostMutation } from '../../feature/posts/postSliceApi'
import { generalAlertError, generalAlertSuccess } from '../../helpers/alert'
import {
  useLazyGetAllBrandsQuery,
  useLazyGetAutoModelsQuery,
  useLazyGetInitialFormDataQuery,
  useLazyGetMotoModelsQuery
} from '../../feature/formData/formDataSliceApi'

function CreatePost() {
  const [storeNewPost, { isLoading }] = useStoreNewPostMutation()
  const navigate = useNavigate()
  const {
    setValue,
    getValues,
    watch,
    formState: { errors },
    handleSubmit,
    control,
    trigger
  } = useForm({
    mode: 'all',
    resolver: yupResolver(validateCreatePost),
    defaultValues: {
      title: '',
      description: '',
      phone: '',
      prefix: '+355',
      email: '',
      post_category_id: '',
      city_id: '',
      price_currency: '',
      price: '',
      price_on_request_title: '',
      vehicle_post: null,
      job_post: null,
      real_estate_post: null,
      cover_image: {
        filename: '',
        content: '',
        fullContent: ''
      },
      gallery: []
    }
  })
  const [triggerFormInitialData, { data, isLoading: formDataLoading }] =
    useLazyGetInitialFormDataQuery()

  const [triggerAllBrands, { data: brandsData, isLoading: brandsLoading }] =
    useLazyGetAllBrandsQuery()

  const [
    triggerAutoModels,
    { data: autoModels, isLoading: autoModelsLoading }
  ] = useLazyGetAutoModelsQuery()
  const [
    triggerMotoModels,
    { data: motoModels, isLoading: motoModelsLoading }
  ] = useLazyGetMotoModelsQuery()

  const cities = data?.data?.cities || []
  const categories = data?.data?.categories || []
  const colors = data?.data?.colors || []
  const engines = data?.data?.engines || []
  const remaining_posts = data?.data?.remaining_posts || 0
  const credit = data?.data?.credit || 0

  const {
    setValue: setValueVehicles,
    watch: watchVehicles,
    formState: { errors: errorsVehicles },
    trigger: triggerVehicles
  } = useForm({
    mode: 'all',
    resolver: yupResolver(vehicleValidation),
    defaultValues: {
      vehicle_post_type: '',
      vehicle_type_id: '',
      auto_brand_id: '',
      auto_model_id: '',
      moto_brand_id: '',
      moto_model_id: '',
      transmission_type: '',
      engine_id: '',
      vehicle_year: '',
      km_done: '',
      color_id: '',
      plate_types: '',
      vehicle_status: ''
    }
  })

  const {
    setValue: setValueJobs,
    watch: watchJobs,
    formState: { errors: errorsJobs },
    trigger: triggerJobs
  } = useForm({
    mode: 'all',
    resolver: yupResolver(jobsValidation),
    defaultValues: {
      job_position: '',
      job_post_type_id: '',
      job_working_hours_id: ''
    }
  })

  const {
    setValue: setValueRealEstate,
    watch: watchRealEstate,
    formState: { errors: errorsRealEstate },
    trigger: triggerRealEstate
  } = useForm({
    mode: 'all',
    resolver: yupResolver(realEstateValidation),
    defaultValues: {
      square_meters: '',
      nr_bedrooms: '',
      nr_bathrooms: '',
      has_garage: '',
      nr_balconies: '',
      has_documentation: '',
      floor_nr: '',
      real_estate_type_id: ''
    }
  })

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'gallery'
  })

  const formData = watch()
  const vehicleFormData = watchVehicles()
  const jobsFormData = watchJobs()
  const realEstateFormData = watchRealEstate()

  const handleCoverChange = (e) => {
    const file = e.target.files[0]

    if (file) {
      // Read file as base64
      const reader = new FileReader()
      reader.onloadend = () => {
        const fileType = file.type
        const base64Content = reader.result
        updateFormData({
          cover_image: {
            filename: file.name,
            content: base64Content.replace('data:' + fileType + ';base64,', ''),
            fullContent: base64Content
          }
        })
      }
      reader.readAsDataURL(file)
    }
  }

  const updateFormData = (data) => {
    const keys = Object.keys(data)
    keys.forEach((key) => {
      setValue(key, data[key], {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true
      })
    })
    trigger(keys)
  }

  const updateFormDataVehicle = (data) => {
    const keys = Object.keys(data)
    keys.forEach((key) => {
      setValueVehicles(key, data[key], {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true
      })
    })
    triggerVehicles(keys)
  }

  const updateFormDataJobs = (data) => {
    const keys = Object.keys(data)
    keys.forEach((key) => {
      setValueJobs(key, data[key], {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true
      })
    })
    triggerJobs(keys)
  }

  const updateFormDataRealEstate = (data) => {
    const keys = Object.keys(data)
    keys.forEach((key) => {
      setValueRealEstate(key, data[key], {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true
      })
    })
    triggerRealEstate(keys)
  }

  const submitPostData = async () => {
    let data = formData

    if (data.post_category_id === '' || data.post_category_id === null) {
      const mainForm = await trigger()
      if (!mainForm) {
        return
      }
    }

    let postType = findSelectedCategoryId(data.post_category_id)
    //? Category 8 = Vehicle
    //? Category 1 = Real Estate
    //? Category 16 = Work

    if (postType.parent_id == 1) {
      const mainForm = await trigger()
      const realEstateForm = await triggerRealEstate()
      if (!mainForm || !realEstateForm) {
        return
      }
    }

    if (postType.parent_id == 8) {
      const mainForm = await trigger()
      const vehicleFrom = await triggerVehicles()

      if (!mainForm || !vehicleFrom) {
        return
      }
    }

    if (postType.parent_id == 16) {
      const mainForm = await trigger()
      const jobsForm = await triggerJobs()
      if (!mainForm || !jobsForm) {
        return
      }
    }

    const mainForm = await trigger()
    if (!mainForm) {
      return
    }

    if (postType.parent_id != 16) {
      data.job_post = null
    }

    if (postType.parent_id == 16) {
      data.job_post = jobsFormData
    }

    if (postType.parent_id != 8) {
      data.vehicle_post = null
    }

    if (postType.parent_id == 8) {
      data.vehicle_post = vehicleFormData
    }

    if (postType.parent_id != 1) {
      data.real_estate_post = null
    }

    if (postType.parent_id == 1) {
      data.real_estate_post = realEstateFormData
    }

    data.phone = data.prefix + data.phone

    delete data.prefix

    const res = await storeNewPost({ data: data })

    if (res.error) {
      generalAlertError('Dicka Shkoi Gabim! Ju lutem provojeni serisht!')
      navigate('/krijo-njoftim')
    } else {
      generalAlertSuccess(
        'Njoftimi juaj u krijua me suksess! Dhe eshte ne process verifikimi!'
      )
      navigate('/')
    }
  }

  useEffect(() => {
    if (formData.price_on_request_title !== '' && formData.price !== '') {
      updateFormData({
        price_on_request_title: ''
      })
    }
  }, [formData.price_on_request_title])

  useEffect(() => {
    triggerFormInitialData()
  }, [])

  useEffect(() => {
    if (formData.post_category_id == 10) {
      setValueVehicles('moto_brand_id', '')
      setValueVehicles('auto_brand_id', '')
      triggerAllBrands({ categoryId: getValues().post_category_id })
      return
    }

    if (formData.post_category_id == 9) {
      setValueVehicles('moto_brand_id', '')
      setValueVehicles('auto_brand_id', '')
      triggerAllBrands({ categoryId: getValues().post_category_id })
      return
    }
  }, [formData.post_category_id])

  useEffect(() => {
    if (vehicleFormData.auto_brand_id !== '') {
      triggerAutoModels({ brandId: vehicleFormData.auto_brand_id })
    }

    if (vehicleFormData.moto_brand_id !== '') {
      triggerMotoModels({ brandId: vehicleFormData.moto_brand_id })
    }
  }, [vehicleFormData.auto_brand_id, vehicleFormData.moto_brand_id])

  const findSelectedCategoryId = (categoryId) => {
    let categorySelected = null

    for (let x = 0; x < categories.length; x++) {
      let item = categories[x]

      for (let i = 0; i < item.children.length; i++) {
        let child = item.children[i]

        if (child.id == categoryId) {
          categorySelected = {
            id: child.id,
            parent_id: item.id
          }
        }
      }
    }
    return categorySelected
  }

  if (formDataLoading) {
    return <Spinner />
  }

  return (
    <div className='my-5 mx-3'>
      <h3 className='mt-5 text-center'>Krijo Postim</h3>
      <div className='row d-flex align-items-center justify-content-center my-4 '>
        <div className='col-12 col-md-9 col-xl-5'>
          <div className='alert alert-primary'>
            <div className='d-flex align-items-center justify-content-between'>
              <div>
                <b>Njoftime te mbetura: {remaining_posts}</b>
              </div>
              <div>
                <b>Kredit: {credit}</b>
              </div>
            </div>
            <hr className='my-2' />
            <p className='m-0' style={{ lineHeight: '18px' }}>
              <b>
                Ne rast se nuk keni njoftime falas te mbetura per publikim, 1
                njoftim i ri kushton 1 Kredit. Ne rast se nuk keni kredit ne
                gjendje njoftimi nuk do procesohet!
              </b>
            </p>
          </div>
          <form>
            <InputTextGroup
              placeholder={'Titulli i Njoftimit'}
              name='title'
              type='text'
              value={formData.title}
              inputGroupClass={'mb-2'}
              inputClass={'py-2'}
              onChange={(e) =>
                updateFormData({
                  title: e.target.value
                })
              }
              error={errors.title?.message}
            />
            <ReactSelectCustom
              placeholder={'Qyteti'}
              name='city_id'
              options={cities.map((c) => ({ value: c.id, label: c.name }))}
              brCustom={'0.375rem'}
              className={'mb-2'}
              values={cities
                .map((c) => ({ value: c.id, label: c.name }))
                .find((el) => el.value == formData.city_id)}
              onChange={(val) =>
                updateFormData({
                  city_id: val ? val.value : ''
                })
              }
              error={errors && errors.city_id?.message}
            />
            <div className='invalid-feedback d-block'>
              {errors.city_id?.message}
            </div>
            <CustomSelectWithGroups
              className={'form-select'}
              name={'post_category_id'}
              placeholder={'Kategori Mix'}
              options={categories}
              value={formData.post_category_id}
              onChange={(e) =>
                updateFormData({
                  post_category_id: e.target.value
                })
              }
              error={errors && errors.post_category_id?.message}
            />
            <div className='invalid-feedback d-block'>
              {errors.post_category_id?.message}
            </div>
            {findSelectedCategoryId(formData.post_category_id) !== null &&
              findSelectedCategoryId(formData.post_category_id).parent_id ==
                8 && (
                <VehiclePostFormComponent
                  updateFormData={updateFormDataVehicle}
                  generalFormData={formData}
                  formData={vehicleFormData}
                  errors={errorsVehicles}
                  brandsLoading={brandsLoading}
                  brands={brandsData ?? []}
                  modelsAutoLoading={autoModelsLoading}
                  modelsMotoLoading={motoModelsLoading}
                  modelsAuto={autoModels ? autoModels.data : []}
                  modelsMoto={motoModels ? motoModels.data : []}
                  engines={engines}
                  colors={colors}
                />
              )}
            {findSelectedCategoryId(formData.post_category_id) !== null &&
              findSelectedCategoryId(formData.post_category_id).parent_id ==
                16 && (
                <JobPostFormComponent
                  updateFormData={updateFormDataJobs}
                  formData={jobsFormData}
                  errors={errorsJobs}
                />
              )}
            {findSelectedCategoryId(formData.post_category_id) !== null &&
              findSelectedCategoryId(formData.post_category_id).parent_id ==
                1 && (
                <RealEstateFormComponent
                  category_id={formData.post_category_id}
                  updateFormData={updateFormDataRealEstate}
                  formData={realEstateFormData}
                  errors={errorsRealEstate}
                />
              )}
            <div className='d-flex align-items-start justify-content-center gap-2'>
              <CustomSelect
                options={countryData.map((d) => ({
                  id: d.phone,
                  name: d.label + ` (${d.phone})`
                }))}
                placeholder={'Prefix'}
                value={formData.prefix}
                className={`form-select py-2 mb-2 w-25 ${
                  errors.prefix?.message ? 'is-invalid' : ''
                }`}
                onChange={(e) =>
                  updateFormData({
                    prefix: e.target.value
                  })
                }
              />
              <div className='invalid-feedback'>{errors.prefix?.message}</div>
              <InputTextGroup
                placeholder={'Numer Kontakti'}
                name='phone'
                type='text'
                inputGroupClass={'mb-2 w-75'}
                inputClass={'py-2'}
                value={formData.phone}
                onChange={(e) =>
                  updateFormData({
                    phone: e.target.value
                  })
                }
                error={errors.phone?.message}
              />
            </div>
            <InputTextGroup
              placeholder={'Email Kontakti'}
              name='email'
              type='text'
              inputGroupClass={'mb-2'}
              inputClass={'py-2'}
              value={formData.email}
              onChange={(e) =>
                updateFormData({
                  email: e.target.value
                })
              }
              error={errors && errors.email?.message}
            />
            <InputTextArea
              placeholder={'Pershkrimi i Postimit'}
              textAreaDivClass={'mb-2'}
              value={formData.description}
              onChange={(e) =>
                updateFormData({
                  description: e.target.value
                })
              }
              error={errors && errors.description?.message}
            />
            <p className='mb-2'>
              Nese nuk doni te beni publik cmimin ju lutem zgjidhni nje nga
              opsionet me poshte
            </p>
            <div className='d-flex gap-3 mb-2'>
              {noPriceTitle.map((item, index) => (
                <InputRadioGroup
                  value={item.id.toString()}
                  name={'price_on_request_title'}
                  checked={formData.price_on_request_title === item.id}
                  labelRadio={item.name}
                  key={index}
                  onChange={(e) =>
                    updateFormData({
                      price_on_request_title: e.target.value
                    })
                  }
                />
              ))}
            </div>
            {formData.price_on_request_title === '' && (
              <div className='d-flex justify-content-center align-items-center gap-2'>
                <InputTextGroup
                  placeholder={'Pagesa/çmimi/Vlera'}
                  name='price'
                  type='text'
                  inputGroupClass={'mb-2 w-75'}
                  inputClass={'py-2'}
                  value={formData.price}
                  onChange={(e) =>
                    updateFormData({
                      price: e.target.value
                    })
                  }
                  error={errors.price?.message}
                />
                <div className='d-flex justify-content-center gap-3 mb-2 w-25 flex-column'>
                  <CustomSelect
                    options={currencies}
                    placeholder={'Valuta'}
                    value={formData.price_currency}
                    className={`form-select py-2 ${
                      errors.price_currency?.message ? 'is-invalid' : ''
                    }`}
                    onChange={(e) =>
                      updateFormData({
                        price_currency: e.target.value
                      })
                    }
                  />
                  {errors.price_currency?.message && (
                    <div className='invalid-feedback d-block'>
                      {errors.price_currency?.message}
                    </div>
                  )}
                </div>
              </div>
            )}
            <div className='bg-primary px-4 py-2 text-center text-white rounded-2 fw-bold'>
              VENDOS KETU FOTON KRYESORE
            </div>
            <InputFileGroup
              labelText={'Foto Coveri'}
              name='cover_image'
              inputGroupClass={'mb-2'}
              inputClass={'py-2'}
              onChange={(e) => handleCoverChange(e)}
              error={errors.cover_image?.content?.message}
            />
            {formData.cover_image.fullContent !== '' && (
              <img
                src={formData.cover_image.fullContent}
                width={100}
                alt='Cover Foto Preview'
              />
            )}

            <div className='bg-primary px-4 py-2 text-center text-white rounded-2 fw-bold'>
              VENDOS KETU FOTO GALERI (OPSIONALE)
            </div>
            <div className='d-flex align-items-start justify-content-start flex-column'>
              <p className='text-danger mt-2 mb-0'>
                Maksimumi i lejuar 10 foto!
              </p>
              <p className='bg-primary px-2 py-1 text-white m-0 rounded-2'>
                Ngarkuar: {formData.gallery.length}/10
              </p>
            </div>
            <div className='my-2'>
              <FileUploader
                setValue={setValue}
                fields={fields}
                append={append}
                remove={remove}
              />
            </div>

            {isLoading ? (
              <button
                className='btn btn-primary w-100 mt-3'
                type='button'
                disabled
              >
                <span
                  className='spinner-border spinner-border-sm'
                  aria-hidden='true'
                ></span>
                <span className='ms-2' role='status'>
                  Loading...
                </span>
              </button>
            ) : (
              <Button
                type={'button'}
                onClick={() => submitPostData()}
                buttonText={'Krijo Postim'}
                buttonClass={'w-100 mt-3 btn-primary'}
              />
            )}
          </form>
        </div>
      </div>
    </div>
  )
}

export default CreatePost
