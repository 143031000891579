// export const BACKEND_API_URL = "http://localhost:8000/api/v1";
// export const BACKEND_STORAGE_URL = "http://localhost:8000/storage";

export const GOOGLE_CLIENT_ID = "912275699125-9fju3gkp0hui0fnk8a9l24u7ohskcur9.apps.googleusercontent.com"
export const GOOGLE_SECRET_KEY = ""
// export const GOOGLE_REDIRECT_URL = "http://localhost:8000/api/v1/auth/google/callback"

export const GOOGLE_REDIRECT_URL = "https://api.okazion.al/api/v1/auth/google/callback"


export const BACKEND_API_URL = "https://api.okazion.al/api/v1";
export const BACKEND_STORAGE_URL = "https://api.okazion.al/storage";
