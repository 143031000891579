import React, { useEffect } from 'react'
import Categories from './components/Categories'
import { Link, useNavigate } from 'react-router-dom'
import HomeSlider from './components/HomeSlider'
import MapSection from './components/MapSection'
import Spinner from '../../components/common/Spinner'
import HomeSearch from '../../components/common/HomeSearch'
import { BACKEND_STORAGE_URL } from '../../config/urls'
import SeoHome from './components/SeoHome'
import { useLazyGetHomeDataQuery } from '../../feature/home/homeSliceApi'
import PostCard from '../../components/common/PostCard'
import { useLazyGetNavigationDataQuery } from '../../feature/formData/formDataSliceApi'
import Pagination from '../../components/common/Pagination'

function Home() {
  const [triggerGetHomeData, { data, isLoading }] = useLazyGetHomeDataQuery()

  const cities = data?.data?.cities || []
  const posts = data?.data?.posts || []
  const banners = data?.data?.banners || []
  const page = data?.data?.posts?.current_page || 0
  const last_page = data?.data?.posts?.last_page || 0

  const [triggerNavbarData, { isLoading: loadData, data: navbarData }] =
    useLazyGetNavigationDataQuery()
  const navigate = useNavigate()

  const categories = navbarData?.data?.categories || []

  useEffect(() => {
    triggerGetHomeData({ page: null })
    triggerNavbarData()
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  if (isLoading || !data) {
    return <Spinner />
  }

  return (
    <>
      <SeoHome />
      <div className='container py-2'>
        <HomeSearch />
        <Categories />
        <div className='row my-3'>
          <div className='col-12 col-lg-7'>
            <div className=''>
              <HomeSlider banners={banners} />
            </div>
          </div>
          <div className='col-12 col-lg-5 mt-2 mt-lg-0'>
            <div className='d-flex align-items-center justify-content-between flex-column gap-2 h-100'>
              {banners
                .filter((banner) => [2, 3, 4].includes(banner.position))
                .map((ban, index) => (
                  <a
                    href={ban.url}
                    target='_blank'
                    rel='noreferrer'
                    className='w-100'
                    key={index}
                  >
                    <img
                      src={BACKEND_STORAGE_URL + ban.image_path}
                      alt='Baner 1'
                      className='img-fluid rounded-2 w-100'
                    />
                  </a>
                ))}
            </div>
          </div>
        </div>
      </div>
      <div className='container py-2'>
        <div className='row my-4'>
          {/* <MapSection countries={{ id: 1, name: 'Shqiperi' }} cities={cities} /> */}
          <div className='col-12'>
            <div className='d-flex align-items-center justify-content-center'>
              <h2 className='text-center border-bottom border-3 border-primary pb-2'>
                Njoftime Premium
              </h2>
            </div>
          </div>
          {posts && posts.data.length !== 0 ? (
            <>
              {posts.data.map((post, index) => (
                <div className='col-12 col-md-12 col-lg-6' key={index}>
                  <PostCard post={post} />
                </div>
              ))}
              <div className='col-12 mt-2'>
                {posts.last_page !== 1 && (
                  <Pagination
                    collectData={triggerGetHomeData}
                    page={page}
                    last_page={last_page}
                    links={posts}
                  />
                )}
              </div>
            </>
          ) : (
            <div className='col-12'>
              <div className='alert alert-primary'>
                Nuk ka njoftime premium ne keto momente!
              </div>
            </div>
          )}
        </div>
      </div>
      <div className='container mb-3'>
        {categories.length > 0 && (
          <div className='row'>
            {categories.map((category, index) => (
              <div className='col-6 col-md-3 col-lg-3 p-1' key={index}>
                <div
                  className='card w-100 h-100 shadow-sm border-0'
                  style={{ cursor: 'pointer' }}
                  onClick={() =>
                    navigate(`/njoftime/kategoria/${category.slug}`)
                  }
                >
                  <div className='card-body'>
                    <h6 className='card-title'>{category.name}</h6>
                    {category.children.map((sub, i) => (
                      <span key={i}>
                        <small> • {sub.name}</small>
                      </span>
                    ))}
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  )
}

export default Home
